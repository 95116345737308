<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="角色列表" />
		<TableContainer title="角色列表">
			<template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">添加角色</el-button>
			</template>
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="roleName" label="角色" min-width="60" />
				<el-table-column prop="remark" label="描述" min-width="130" />
				<el-table-column prop="createTime" label="创建日期" min-width="100" />
				<el-table-column width="100" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">编辑</el-button>
						<!-- <el-button type="text" class="textOrange" @click="textOrange(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="角色名称" prop="roleName">
					<el-input v-model="themeForm.roleName" maxlength="35" placeholder="请输入角色名称" />
				</el-form-item>
				<el-form-item label="描述" prop="remark">
					<el-input v-model="themeForm.remark" maxlength="35" placeholder="请输入描述" />
				</el-form-item>
				<el-form-item label="权限" prop="list">
					<el-tree :data="list" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
						v-model="themeForm.menuTree" :props="defaultProps" @check="checkHandler"
						:default-expand-all="false" :check-strictly="isCheck" />
					<!-- <el-tree ref="tree" class="el-tree" show-checkbox node-key="id" :data="list"
						:props="defaultProps" :highlight-current="true" :default-expand-all="false"
						:render-content="renderContent" @node-expand="handleExpand" :check-strictly="isCheck" /> -->

				</el-form-item>
			</el-form>
		</GDialog>
		<GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm1('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="角色名称" prop="roleName">
					<el-input v-model="themeForm.roleName" maxlength="35" placeholder="请输入角色名称" />
				</el-form-item>
				<el-form-item label="描述" prop="remark">
					<el-input v-model="themeForm.remark" maxlength="35" placeholder="请输入描述" />
				</el-form-item>
				<el-form-item label="权限" prop="list">
					<el-tree :data="list" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
						v-model="themeForm.menuTree" :props="defaultProps" @check="checkHandler"
						:default-expand-all="false" :check-strictly="isCheck" />
					<!-- <el-tree ref="tree" class="el-tree" show-checkbox node-key="id" :data="list"
						:props="defaultProps" :highlight-current="true" :default-expand-all="false"
						:render-content="renderContent" @node-expand="handleExpand" :check-strictly="isCheck" /> -->

				</el-form-item>
			</el-form>
		</GDialog>

	</section>

</template>

<script>
	import {
		getMean,
		addRule,
		selectRule,
		updateRule
	} from '@api/administratorAction/api_administratorAction';
	export default {
		data() {
			return {
				tableData: [],
				list: [],
				total: 0,
				isCheck: false,
				searchForm: {
					page: 1,
					size: 10
				},
				themeDialog: {
					title: '标题哦',
					visible: false
				},
				themeDialog1: {
					title: '标题哦',
					visible: false
				},
				themeForm: {
					roleName: '',
					remark: '',
					menuTree: [],
				},
				rules: {
					roleName: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}],
					remark: [{
							required: true,
							message: '请输入描述',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
				},
				defaultProps: {
					children: "children",
					label: "menuName",
				},
			}
		},
		created() {
			this.selectRule()
		},
		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			checkHandler(...value) {
				let a = value[1].checkedNodes.map((a) => a.id);
				let b = value[1].checkedNodes.map((a) => a.id);
				this.themeForm.menuTree = a.toString();
				console.log(a);
				// this.questionForm.menuName = a;
				// this.questionForm.permissionToken = b;
			},
			selectRule() {
				let params = {}
				selectRule(params).then(res => {
					this.tableData = res
				})
			},
			fetchData() {

			},
			editGarden(row) {
				console.log(row)
				this.themeDialog1.visible = true
				this.themeDialog1.title = '编辑权限'
				this.getMean()
				this.themeForm = row
				let a = row.menuTree
				this.themeForm.menuTree = a.split(',').map(Number)
				console.log(this.themeForm.menuTree)
				this.isCheck = true //重点：回显之前一定要设置为true
				this.$nextTick(() => {
					console.log(this.$refs)
					this.$refs.tree.setCheckedKeys(this.themeForm.menuTree) //给树节点赋值回显
					this.isCheck = false //重点： 赋值完成后 设置为false
				})

			},
			getMean() {
				let params = {
					type: 0
				}
				getMean(params).then((res) => {
					console.log(res)
					this.list = res
					// this.list.push({
					// 	children: res.children,
					// 	label: res.menuName,
					// 	// children: this.fun1(res)
					// })
				})
			},

			addTheme() {
				this.themeForm = [];
				this.themeDialog.visible = true;
				this.themeDialog.title = "添加角色"
				this.getMean()

			},
			submitThemeForm(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					addRule(params).then(res => {
						this.$message.success(this.themeDialog.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.selectRule()
						// this.$refs.ref_addCourse.getOptions()
					})

				})

			},
			submitThemeForm1(formName) {
				console.log(formName, 1111)
				let a = this.themeForm.menuTree;
				this.themeForm.menuTree = a.toString();
				this.$refs[formName].validate(valid => {
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					updateRule(params).then(res => {
						this.$message.success(this.themeDialog1.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.selectRule()
						// this.$refs.ref_addCourse.getOptions()
					})

				})

			},
			resetThemeForm(formName) {
				this.themeDialog.visible = false
				this.themeDialog1.visible = false
			}

		}
	}
</script>

<style>
	/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}
	
	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}
	
	.el-button--text.reviewed{
		color: #38b9be;
	}
	
	.reviewed {
		color: #38b9be;
	}
	
	.el-tree-node__content {
		z-index: 999;
		padding-left: 40px;

	}
</style>
